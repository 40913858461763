.skills__list {
  max-width: 450px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: default;
}

.skills {
  cursor: default;
}

.skills__list-item {
  margin: 0.5em;
  cursor: default;
}

.skills__list-item btn btn--plain {
  cursor: default;
}
